
  import { defineComponent } from 'vue'
  import TrackList from '@/library/track/TrackList.vue'

  export default defineComponent({
    components: {
      TrackList,
    },
    props: {
      id: { type: String, required: true },
    },
    data() {
      return {
        loading: false,
        hasMore: true,
        items: [] as any[]
      }
    },
    computed: {
      generator() {
        return this.$api.getTracksByArtist(this.id)
      }
    },
    methods: {
      async loadMore() {
        this.loading = true
        const { value, done } = await this.generator.next()
        if (value !== undefined) {
          this.items.push(...value)
        }
        this.hasMore = !done
        this.loading = false
      }
    }
  })
