
  import { defineComponent, ref } from 'vue'
  import { usePlaylistStore } from '@/library/playlist/store'

  export default defineComponent({
    props: {
      visible: { type: Boolean, required: true },
    },
    setup(props, { emit }) {
      const name = ref('')
      const store = usePlaylistStore()
      return {
        name,
        confirm: () => {
          store.create(name.value)
        },
        change: () => {
          name.value = ''
          emit('update:visible', false)
        },
      }
    },
  })
