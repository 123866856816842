
  import { computed, defineComponent, ref } from 'vue'
  import CreatePlaylistModal from '@/library/playlist/CreatePlaylistModal.vue'
  import { orderBy } from 'lodash-es'
  import { usePlaylistStore } from '@/library/playlist/store'

  export default defineComponent({
    components: {
      CreatePlaylistModal,
    },
    props: {
      sort: { type: String, default: null },
    },
    setup(props) {
      const store = usePlaylistStore()
      return {
        showAddModal: ref(false),
        loading: computed(() => store.playlists === null),
        items: computed(() =>
          props.sort === 'a-z'
            ? orderBy(store.playlists, 'name')
            : orderBy(store.playlists, 'createdAt', 'desc')),
      }
    },
    methods: {
      async playNow(id: string) {
        const playlist = await this.$api.getPlaylist(id)
        return this.$store.dispatch('player/playTrackList', {
          tracks: playlist.tracks,
        })
      },
      async playNext(id: string) {
        const playlist = await this.$api.getPlaylist(id)
        return this.$store.dispatch('player/setNextInQueue', playlist.tracks)
      },
      async playLater(id: string) {
        const playlist = await this.$api.getPlaylist(id)
        return this.$store.dispatch('player/addToQueue', playlist.tracks)
      },
    }
  })
