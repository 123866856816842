
  import { defineComponent } from 'vue'
  import { config } from '@/shared/config'
  import Logo from '@/app/Logo.vue'
  import { useMainStore } from '@/shared/store'
  import { useAuth } from '@/auth/service'
  import { BOverlay } from 'bootstrap-vue'

  export default defineComponent({
    components: {
      BOverlay,
      Logo,
    },
    props: {
      returnTo: { type: String, required: true },
    },
    setup() {
      return {
        store: useMainStore(),
        auth: useAuth(),
      }
    },
    data() {
      return {
        server: '',
        username: '',
        password: '',
        busy: false,
        error: null,
        displayForm: false,
      }
    },
    computed: {
      hasError(): boolean {
        return this.error !== null
      },
      config: () => config
    },
    async created() {
      this.server = this.auth.server
      this.username = this.auth.username
      const success = await this.auth.autoLogin()
      if (success) {
        this.store.setLoginSuccess(this.username, this.server)
        await this.$router.replace(this.returnTo)
      } else {
        this.displayForm = true
      }
    },
    methods: {
      login() {
        this.error = null
        this.busy = true
        this.auth.loginWithPassword(this.server, this.username, this.password)
          .then(() => {
            this.store.setLoginSuccess(this.username, this.server)
            this.$router.replace(this.returnTo)
          })
          .catch(err => {
            this.error = err
          })
          .finally(() => {
            this.busy = false
          })
      }
    }
  })
